//login
export const SIGN_IN = "SIGN_IN";

//user
export const GET_USERS = "GET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const GET_SINGLE_USER = "GET_SINGLE_USER";

//language
export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

//COUNTRY
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const UPDATE_COUNTRIE = "UPDATE_COUNTRIE";

//CATEGORY
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const SET_CATEGORY = "SET_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

//LABELS
export const GET_ALL_LABELS = "GET_ALL_LABELS";
export const SET_LABEL = "SET_LABEL";
export const UPDATE_LABEL = "UPDATE_LABEL";

//Commission
export const GET_COMMISSION = "GET_COMMISSION";
export const SET_COMMISSION = "SET_SINGLE_USER";

// PLAN
export const GET_ALL_PLAN = "GET_ALL_PLAN";
export const SET_PLAN = "SET_PLAN";
export const UPDATE_PLAN = "UPDATE_PLAN";

// PAYOUT
export const GET_ALL_PAYOUT = "GET_ALL_PAYOUT";
export const SET_PAYOUT = "SET_PAYOUT";
export const GET_ALL_LISTENER_BALANACE = "GET_ALL_LISTENER_BALANACE";

// DASHOAED_DATA
export const GET_ALL_DASHOAED_DATA = "GET_ALL_DASHOAED_DATA";

// FEEDBACK
export const GET_ALL_FEEDBACK = "GET_ALL_FEEDBACK";

//NOTIFICATIONS
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

//BANNER
export const GET_ALL_BANNER = "GET_ALL_BANNER";
export const SET_BANNER = "SET_BANNER";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
