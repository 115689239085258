import { lazy } from "react";

const RoutesPaths = [
  {
    path: "/login",
    component: lazy(() => import("../../Components/SignIn/Login")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/",
    component: lazy(() => import("../../Components/Home/Home")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/user",
    component: lazy(() => import("../../Components/UsersData/AllUser")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/user/profile/:id",
    component: lazy(() =>
      import("../../Components/UsersData/Profile/UserProfile")
    ),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/listener",
    component: lazy(() => import("../../Components/ListenerData/AllListener")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/commission",
    component: lazy(() => import("../../Components/Commission/Commission.jsx")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/listener/profile/:id",
    component: lazy(() =>
      import("../../Components/ListenerData/Profile/ListenerProfile")
    ),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/language",
    component: lazy(() => import("../../Components/Language/Language")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/countries",
    component: lazy(() => import("../../Components/Countries/Countries")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/categories",
    component: lazy(() => import("../../Components/Categories/Categories")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/labels",
    component: lazy(() => import("../../Components/Labels/AllLabels")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/PendingListnerApproval",
    component: lazy(() => import("../../Components/Home/Pending/Pending")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/plan",
    component: lazy(() => import("../../Components/Plan/Plan")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/payout",
    component: lazy(() => import("../../Components/Payout/Payout")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/addPayout",
    component: lazy(() => import("../../Components/Payout/AddPayout")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/feedback",
    component: lazy(() => import("../../Components/FeedBack/FeedBack")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/admin-notification",
    component: lazy(() =>
      import("../../Components/Notifications/Notifications")
    ),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/banner",
    component: lazy(() =>
      import("../../Components/Slider/Slider.jsx")
    ),
    meta: {
      authRoute: true,
    },
  },
];

export default RoutesPaths;
