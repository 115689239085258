import * as types from "./ActionType";

const initialstate = {
  token: null,
  users: [],
  language: [],
  countries: [],
  categories: [],
  labels: [],
  commission: [],
  plan: [],
  payout: [],
  listerBalance: {},
  dashboardData: [],
  feedback: [],
  notifications: [],
  banner: [],
};

const userReducres = (state = initialstate, action) => {
  switch (action.type) {
    case types.SIGN_IN:
      return {
        ...state,
        token: action.payload, // Store the token in the state
      };
    case types.GET_USERS:
      return {
        ...state,
        users: action.payload, // Store All User in the state
        loading: false,
      };
    case types.UPDATE_USER:
      return {
        ...state,
        users: state.users.map((users) =>
          users._id === action.payload.usersId
            ? { ...users, ...action.payload.updateduser }
            : users
        ),
      };
    case types.GET_ALL_LANGUAGE:
      return {
        ...state,
        language: action.payload, // Store All language in the state
        loading: false,
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: [...state.language, action.payload],
      };
    case types.UPDATE_LANGUAGE:
      return {
        ...state,
        language: state.language.map((language) =>
          language._id === action.payload.languageId
            ? { ...language, ...action.payload.updatedLanguage }
            : language
        ),
      };
    case types.GET_ALL_COUNTRIES:
      return {
        ...state,
        countries: action.payload, // Store All countries in the state
        loading: false,
      };
    case types.SET_COUNTRIES: // Handle ADD_LANGUAGE action
      return {
        ...state,
        countries: [...state.countries, action.payload],
      };
    case types.DELETE_COUNTRY:
      const deletedCountry = action.payload.countryId;
      return {
        ...state,
        countries: state.countries.filter(
          (client) => client._id !== deletedCountry
        ),
      };

    case types.UPDATE_COUNTRIE:
      return {
        ...state,
        countries: state.countries.map((countries) =>
          countries._id === action.payload.countriesId
            ? { ...countries, ...action.payload.updatedCountriesi }
            : countries
        ),
      };
    case types.GET_ALL_CATEGORY:
      return {
        ...state,
        categories: action.payload, // Store All countries in the state
        loading: false,
      };
    case types.SET_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    case types.UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((categories) =>
          categories._id === action.payload.categoriId
            ? { ...categories, ...action.payload.updatedCategori }
            : categories
        ),
      };
    case types.GET_ALL_LABELS:
      return {
        ...state,
        labels: action.payload, // Store All labels in the state
        loading: false,
      };
    case types.SET_LABEL: // Handle SET_LABEL action
      return {
        ...state,
        labels: [...state.labels, action.payload], // Add the new labels to the array
      };
    case types.UPDATE_LABEL:
      return {
        ...state,
        labels: state.labels.map((labels) =>
          labels._id === action.payload.labelId
            ? { ...labels, ...action.payload.updatedLabels }
            : labels
        ),
      };
    case types.GET_COMMISSION:
      return {
        ...state,
        commission: action.payload, // Store All GET_COMMISSION in the state
        loading: false,
      };
    case types.SET_COMMISSION: // Handle SET_COMMISSION action
      return {
        ...state,
        commission: [...state.commission, action.payload],
      };
    case types.GET_ALL_PLAN:
      return {
        ...state,
        plan: action.payload,
        loading: false,
      };
    case types.SET_PLAN:
      return {
        ...state,
        plan: [...state.plan, action.payload],
      };
    case types.UPDATE_PLAN:
      return {
        ...state,
        plan: state.plan.map((plan) =>
          plan._id === action.payload.planId
            ? { ...plan, ...action.payload.updatedplan }
            : plan
        ),
      };

    case types.GET_ALL_PAYOUT:
      return {
        ...state,
        payout: action.payload,
        loading: false,
      };
    case types.SET_PAYOUT:
      return {
        ...state,
        payout: [...state.payout, action.payload],
      };
    case types.GET_ALL_LISTENER_BALANACE:
      return {
        ...state,
        listerBalance: action.payload,
        loading: false,
      };

    case types.GET_ALL_DASHOAED_DATA:
      return {
        ...state,
        dashboardData: action.payload,
        loading: false,
      };

    case types.GET_ALL_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,
        loading: false,
      };
    case types.GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
      };
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case types.DELETE_NOTIFICATION:
      const deletedNotification = action.payload.notificationId;
      return {
        ...state,
        notifications: state.notifications.filter(
          (client) => client._id !== deletedNotification
        ),
      };

    case types.UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notifications) =>
          notifications._id === action.payload.notificationsId
            ? { ...notifications, ...action.payload.updatedNotificationsi }
            : notifications
        ),
      };

    case types.GET_ALL_BANNER:
      return {
        ...state,
        banner: action.payload,
        loading: false,
      };
    case types.SET_BANNER:
      return {
        ...state,
        banner: [...state.banner, action.payload],
      };
    case types.DELETE_BANNER:
      const deletedBanner = action.payload.bannerId;
      return {
        ...state,
        banner: state.banner.filter((client) => client._id !== deletedBanner),
      };

    case types.UPDATE_BANNER:
      return {
        ...state,
        banner: state.banner.map((banner) =>
          banner._id === action.payload.bannerId
            ? { ...banner, ...action.payload.updatedBanner }
            : banner
        ),
      };
    default:
      return state;
  }
};

export default userReducres;
