import React, { Suspense } from "react";
import Router from "./Admin/Routers/router";
import SideBar from "./Admin/Components/LayOut/SideBar";
import { useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const App = () => {
  const location = useLocation();
  const { pathname } = location;
  const shouldRenderNavbar = ["/login"].includes(pathname);
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 2500}}
        containerStyle={{ zIndex: 99999999}} // For the container
      />
      <div className="flex flex-col h-full ">
        {!shouldRenderNavbar && (
          <div className="w-full">
            <div className="w-full">
              <SideBar />
            </div>
          </div>
        )}

        <div
          className={`${shouldRenderNavbar? "lg:ml-0": "lg:ml-20 lg:pl-24 lg:pr-5 md:p-0 mt-5 p-5 mb-10"}`}>
          <Suspense fallback={<>Loding...</>}>
            <Router />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default App;
