import openeye from "../img/openeye.png";
import hiddenEye from "../img/hiddenEye.png";

const userDataSignIn = ({
  error,
  email,
  onChangeField,
  showPass,
  password,
  onShowPassword,
}) => {
  return [
    {
      id: 0,
      errorusername: error.email,
      type: "text",
      placeholder: "User Email",
      value: email,
      field: "email", // Add field property for identifying the field
      onChange: onChangeField,
      img: "fa-solid fa-envelope",
    },
    {
      id: 1,
      errorusername: error.password,
      type: showPass ? "text" : "password",
      placeholder: "Password",
      value: password,
      field: "password", // Add field property for identifying the field
      onChange: onChangeField,
      img: "fa-solid fa-lock",
      passwordimg: showPass ? openeye : hiddenEye,
      onShowPassword: onShowPassword,
    },
  ];
};

const editeUser = ({ email, userName, phNo, password }) => {
  return [
    {
      id: 1,
      name: "email",
      placeholder: "User Email",
      value: email || "",
      img: "fa-solid fa-envelope",
    },
    {
      id: 2,
      name: "userName",
      placeholder: "User Name",
      value: userName || "",
      img: "fa-solid fa-user",
    },
    {
      id: 3,
      name: "phNo",
      placeholder: "User Phone Number",
      value: `+91 ${phNo}` || "",
      img: "fa-solid fa-phone",
    },
    {
      id: 4,
      name: "password",
      placeholder: "User Phone Number",
      value: password || "",
      img: "fa-solid fa-lock",
    },
  ];
};
const headerdata = [
  {
    id: 0,
    pathname: "/",
    pagename: "Dashboard",
    img: "fa-solid fa-gauge",
  },
  {
    id: 1,
    pathname: "/user",
    pagename: "User",
    img: "fa-solid fa-users",
  },

  {
    id: 2,
    pathname: `/listener`,
    pagename: "Listener",
    img: "fa-solid fa-headset",
  },

  {
    id: 3,
    pathname: `/countries`,
    pagename: "Countries",
    img: "fa-solid fa-globe",
  },
  {
    id: 4,
    pathname: `/categories`,
    pagename: "Categories",
    img: "fa-solid fa-bars-staggered",
  },
  {
    id: 5,
    pathname: `/labels`,
    pagename: "Labels",
    img: "fa-solid fa-solid fa-tag",
  },
  {
    id: 6,
    pathname: `/Language`,
    pagename: "All Language",
    img: "fa-solid fa-language",
  },
  {
    id: 7,
    pathname: `/commission`,
    pagename: "All Commission",
    img: "fa-solid fa-hand-holding-dollar",
  },
  {
    id: 8,
    pathname: `/plan`,
    pagename: "Plan",
    img: "fa-solid fa-hand-holding-dollar",
  },
  {
    id: 9,
    pathname: `/payout`,
    pagename: "Payout",
    img: "fa-solid fa-hand-holding-dollar",
  },
  {
    id: 10,
    pathname: `/feedback`,
    pagename: "Feed Back",
    img: "fa-solid fa-comment",
  },
  {
    id: 11,
    pathname: `/admin-notification`,
    pagename: "All  Notification",
    img: "fa-solid fa-globe",
  },
  {
    id: 12,
    pathname: `/banner`,
    pagename: "All Banner",
    img: "fa-solid fa-globe",
  },
];

const addLanguage = ({ error, LanguageName, LanguageCode, onChangeField }) => {
  return [
    {
      id: 1,
      errorusername: error?.languageName,
      type: "LanguageName",
      placeholder: "Language Name",
      value: LanguageName,
      onChange: onChangeField,
      img: "fa-solid fa-language",
    },
    {
      id: 2,
      errorusername: error?.languageCode,
      type: "LanguageCode",
      placeholder: "Language Code",
      value: LanguageCode,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
  ];
};
const addCategories = ({ error, Name, Image, onChangeField }) => {
  return [
    {
      id: 1,
      errorusername: error?.Name,
      type: "Name",
      text: "text",
      placeholder: "Name",
      value: Name,
      onChange: onChangeField,
      img: "fa-solid fa-language",
    },
    {
      id: 2,
      errorusername: error?.Image,
      type: "Image",
      text: "file",
      placeholder: "Image",
      value: Image,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
  ];
};
const addCountry = ({
  error,
  countryName,
  countryCode,
  currencyCode,
  phoneCode,
  onChangeField,
}) => {
  return [
    {
      id: 1,
      errorusername: error?.countryName,
      type: "countryName",
      placeholder: "Country Name",
      value: countryName,
      onChange: onChangeField,
      img: "fa-solid fa-globe",
    },
    {
      id: 2,
      errorusername: error?.countryCode,
      type: "countryCode",
      placeholder: "Country Code",
      value: countryCode,
      onChange: onChangeField,
      img: "fa-solid fa-globe",
    },
    {
      id: 3,
      errorusername: error?.currencyCode,
      type: "currencyCode",
      placeholder: "Currency Name",
      value: currencyCode,
      onChange: onChangeField,
      img: "fa-solid fa-wallet",
    },
    {
      id: 4,
      errorusername: error?.phoneCode,
      type: "phoneCode",
      placeholder: "Phone Code",
      value: phoneCode,
      onChange: onChangeField,
      img: "fa-solid fa-phone",
    },
  ];
};

const addBaner = ({
  error,
  name,
  title,
  description,
  link,
  image,
  status,
  onChangeField,
}) => {
  return [
    {
      id: 1,
      error: error?.name,
      type: "name",
      placeholder: "name",
      value: name,
      onChange: onChangeField,
      img: "fa-solid fa-globe",
    },
    {
      id: 2,
      error: error?.title,
      type: "title",
      placeholder: "title",
      value: title,
      onChange: onChangeField,
      img: "fa-solid fa-globe",
    },
    {
      id: 3,
      error: error?.description,
      type: "description",
      placeholder: "description",
      value: description,
      onChange: onChangeField,
      img: "fa-solid fa-wallet",
    },
    {
      id: 4,
      error: error?.link,
      type: "link",
      placeholder: "link",
      value: link,
      onChange: onChangeField,
      img: "fa-solid fa-phone",
    },
    {
      id: 5,
      error: error?.image,
      type: "Image",
      text: "file",
      placeholder: "image",
      value: image,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
  ];
};
const addLabels = ({ error, Code, Title, Langue, Status, onChangeField }) => {
  return [
    {
      id: 1,
      errorusername: error?.Code,
      type: "Code",
      placeholder: "Language Code",
      value: Code,
      onChange: onChangeField,
      img: "fa-solid fa-language",
    },
    {
      id: 2,
      errorusername: error?.Title,
      type: "Title",
      placeholder: "Language Title",
      value: Title,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
    {
      id: 3,
      errorusername: error?.Langue,
      type: "Langue",
      placeholder: "Language",
      value: Langue,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
    {
      id: 4,
      errorusername: error?.Status,
      type: "Status",
      placeholder: "Status",
      value: Status,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
      options: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
    },
  ];
};
const addCommissions = ({ error, Amount, Note, onChangeField }) => {
  return [
    {
      id: 1,
      errorusername: error?.Amount,
      type: "Amount",
      placeholder: "Commissions Amount",
      value: Amount,
      onChange: onChangeField,
      img: "fa-solid fa-language",
    },
    {
      id: 2,
      errorusername: error?.Note,
      type: "Note",
      placeholder: "Commissions Note",
      value: Note,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
  ];
};

const addPlan = ({
  error,
  title,
  amount,
  duration,
  minutes,
  tage,
  onChangeField,
}) => {
  return [
    {
      id: 1,
      errorusername: error?.Title,
      type: "title",
      placeholder: "Commissions title",
      value: title,
      onChange: onChangeField,
      img: "fa-solid fa-language",
    },
    {
      id: 2,
      errorusername: error?.Amount,
      type: "amount",
      placeholder: "Commissions amount",
      value: amount,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
    {
      id: 3,
      errorusername: error?.duration,
      type: "duration",
      placeholder: "Commissions duration",
      value: duration,
      onChange: onChangeField,
      img: "fa-solid fa-language",
    },
    {
      id: 4,
      errorusername: error?.minutes,
      type: "minutes",
      placeholder: "Commissions minutes",
      value: minutes,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
    {
      id: 5,
      errorusername: error?.tage,
      type: "tage",
      placeholder: "Commissions tag",
      value: tage,
      onChange: onChangeField,
      img: "fa-solid fa-handshake",
    },
  ];
};

const addNotification = ({
  error,
  title,
  body,
  date,
  notifyFor,
  onChangeField,
}) => {
  return [
    {
      id: 1,
      errorusername: error?.Title,
      type: "title",
      placeholder: "Title",
      value: title,
      onChange: onChangeField,
      img: "fa-solid fa-globe",
    },
    {
      id: 2,
      errorusername: error?.Body,
      type: "body",
      placeholder: "Message",
      value: body,
      onChange: onChangeField,
      img: "fa-solid fa-globe",
    },
    {
      id: 3,
      error: error?.notifyFor,
      field: "notifyFor",
      type: "select",
      placeholder: "Notification For",
      value: notifyFor,
      options: [
        { label: "Venters", value: 1 },
        { label: "Listeners", value: 2 },
        { label: "Both", value: 3 },
      ],
    },
    {
      id: 4,
      errorusername: error?.Date,
      type: "datetime-local",
      placeholder: "date",
      value: date,
      onChange: onChangeField,
      img: "fa-solid fa-wallet",
    },
  ];
};

export {
  userDataSignIn,
  editeUser,
  headerdata,
  addLanguage,
  addCountry,
  addCategories,
  addLabels,
  addCommissions,
  addPlan,
  addNotification,
  addBaner,
};
