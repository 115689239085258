import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { EnglishConstant } from "../library/languages/englishMessages";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../style";
import { headerdata } from "../Data/AllMapingData";
import Logo from "../img/venting_logo_h.png";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [OpenBar, setOpenBar] = useState(false);

  /*=============LOGOUT FUNCTION===========*/
  const handleogout = (e) => {
    e.preventDefault();
    localStorage.clear("user");
    navigate("/");
    toast.error(EnglishConstant.loggedout);
  };
  return (
    <div>
      <div className="min-h-screen b bg-white fixed z-50 overflow-y-auto">
        <div
          className={`sidebar min-h-screen lg:block hidden p-3 w-40  hover:shadow-lg`}
        >
          <div className="flex h-screen flex-col justify-start ">
            <div className={`${styles.flexBetween} flex-col h-full  `}>
              <ul className="flex  flex-col gap-4 tracking-wide ">
                <li>
                  <img
                    src={Logo}
                    alt=""
                    srcSet=""
                    className="w-14 mx-auto my-3 "
                  />
                </li>
                {headerdata?.map((data) => (
                  <li
                    className={`min-w-max ${data.dropdown ? "relative" : ""}`}
                    key={data.id}
                  >
                    <Link
                      to={data.pathname}
                      className={`${
                        data.pathname === pathname
                          ? "text-white rounded-lg  bg-blue-800"
                          : "text-[#6a80a7]"
                      } flex gap-2 justify-start items-center rounded-lg hover:text-white hover:bg-blue-800 px-2 py-1`}
                    >
                      <span className="inline-flex ">
                        <i className={`${data.img}  text-base `}></i>
                      </span>
                      <span className="tracking-wide  truncate">
                        {data.pagename}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className=" mb-5  flex flex-col gap-3 text-[#6a80a7] items-start cursor-pointer rounded-lg hover:text-white hover:bg-blue-800 px-4 py-1">
                <div
                  className="flex items-center gap-3 "
                  onClick={(e) => handleogout(e)}
                >
                  <i className="fa-solid fa-right-from-bracket text-xl "></i>
                  <p>Sign Out</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {OpenBar ? (
          ""
        ) : (
          <div
            className="lg:hidden fixed bottom-5 left-5"
            onClick={() => setOpenBar(!OpenBar)}
          >
            <i className="fa-solid fa-bars text-3xl border-2 text-white p-3 rounded-xl border-[#030239] bg-[#030239]"></i>
          </div>
        )}

        {OpenBar && (
          <nav
            className={`${
              OpenBar && "sidebar"
            } inset-x-0 flex lg:hidden  relative flex-col justify-around h-screen text-sm  z-[999999] p-1 `}
          >
            <div
              className="absolute top-5 right-5 "
              onClick={() => setOpenBar(!OpenBar)}
            >
              <i className="fa-solid fa-xmark  text-xl text-white"></i>
            </div>
            <ul className=" flex flex-col justify-center space-y-2 tracking-wide mt-10 items-center gap-5 ">
              {headerdata?.map((data) => (
                <li
                  className={`min-w-max ${data.dropdown ? "relative" : ""}`}
                  key={data.id}
                >
                  <Link
                    to={data.pathname}
                    className="flex flex-col justify-center items-center gap-2 text-white hover:text-black"
                  >
                    <span className="inline-flex">
                      <i className={`${data.img} text-xl `}></i>
                    </span>
                    <span className="tracking-wide truncate">
                      {data.pagename}
                    </span>
                  </Link>
                </li>
              ))}

              <li
                className="flex items-center px-3 gap-3 text-white hover:text-black"
                onClick={(e) => handleogout(e)}>
                <i className="fa-solid fa-right-from-bracket text-xl "></i>
                <p>Sign Out</p>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
}

export default SideBar;
